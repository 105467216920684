body {
  font-size: 17px;
  color: black;
}
/* Header */
.logo {
  font-size: 26px;
  font-weight: 700;
  color: #012970;
  font-family: "Nunito", sans-serif;
  cursor: pointer;
}
/* Search Bar */
.search-bar {
  display: inline-flex;
}
/* Breadcrumbs */
.breadcrumb {
  font-size: 14px;
  font-family: "Nunito", sans-serif;
  font-weight: bold;
}
.breadcrumb a {
  color: #899bbd;
  transition: 0.3s;
}
.breadcrumb a:hover {
  color: #51678f;
}
.breadcrumb .breadcrumb-item::before {
  color: #899bbd;
}
.breadcrumb .active {
  color: #165411;
}
/* Table of Content */
#cwwlTblVol {
  margin-left: 20px;
  font-weight: bold;
  cursor: pointer;
  font-size: 17px;
  color: #261e10;
}
/* Book Title */
#bookTitle {
  color: #1e0f00;
  margin-bottom: 5px;
}
#cwwlYearVol {
  color: #2c8a25;
}

/* Reading Content */
#readingContent {
  margin-top: 60px;
  padding: 60px 30px 20px 30px;
  transition: all 0.3s;
}
#tabLink {
  padding-left: 30px;
  padding-right: 30px;
  z-index: 1;
  height: 55px;
  position: fixed;
  border-top: 1px solid #165411;
  border-bottom: 1px solid #165411;
}
#tabLink a {
  color: #1e0f00;
  font-weight: bold;
}
#prevNext > span {
  color: #2c8a25;
  font-weight: bold;
}
h1.prefacetitle {
  color: #1e0f00;
  font-size: 17px;
  margin-bottom: 2em;
  text-align: center;
  font-weight: bold;
}
h1.msg {
  color: #1e0f00;
  font-size: 16px;
  text-align: center;
  font-weight: bold;
}
#readingContent h2.head1 {
  text-align: center;
  font-size: 17px;
  font-weight: bold;
  margin-top: 1em;
  color: #1e0f00;
}
#readingContent h2.head3 {
  text-align: center;
  font-size: 17px;
  font-weight: bold;
  margin: 1em 0em;
  color: #1e0f00;
}
#readingContent h1.group {
  text-align: center;
  font-size: 17px;
  font-weight: bold;
  margin: 1em 0em;
  color: #1e0f00;
}
#readingContent ol.otl6 {
  list-style-type: none;
  margin-left: 1.5em;
}
#readingContent ol.otl6 li {
  list-style-type: none;
  text-indent: -1.5em;
}
#readingContent ol.otl5 {
  list-style-type: none;
}
#readingContent ol.otl5 li {
  list-style-type: none;
  text-indent: -1.25em;
}
#readingContent .romanbold ol.otl1 > li {
  font-weight: bold;
}
#readingContent ol > li,
#readingContent ul > li,
#readingContent .nobold {
  font-weight: normal;
}
#readingContent .partnum,
#readingContent .parttitle {
  text-align: center;
  font-size: 17px;
  font-weight: bold;
  color: #1e0f00;
}

h1.title,
h2.head4,
h2.head2 {
  color: #1e0f00;
  text-align: center;
  font-size: 17px;
  font-weight: bold;
}
h2.head3 {
  text-align: center;
  font-size: 17px;
  font-weight: bold;
  font-style: italic;
}
.toc {
  text-align: center;
  font-weight: bold;
  color: #1e0f00;
  margin-bottom: 8px;
}
#readingContent .section > p {
  text-indent: 1.5em;
  text-align: justify;
}
.section > p.address,
.section > p.salutation {
  text-indent: 0px !important;
}
.pg1 {
  text-align: right !important;
  font-size: 13px !important;
  color: #a7a7a7;
}
.pg {
  font-size: 13px !important;
  color: #a7a7a7;
}
/* Scripture */
p.scripture {
  font-size: 16px;
  text-align: left;
  margin-bottom: 0.5em;
  margin-left: 1.5em;
  text-indent: 0;
}
p.msginfo {
  font-size: 16px !important;
  text-indent: 0;
  margin-left: 1.5em;
}
/* Quote */
#readingContent p.quote {
  font-size: 16px !important;
  margin-left: 3em !important;
  margin-right: 3em !important;
  text-indent: 0 !important;
}
#readingContent p.quoteindent {
  font-size: 16px !important;
  margin-left: 3em !important;
  margin-right: 3em !important;
}
/* Outline */
ol > li,
ol.otl1 > li,
ol.otl2 > li {
  font-size: 17px;
}
ol.otl1 {
  list-style-type: upper-roman;
  margin-bottom: 1.5em;
}
ol.otl2 {
  list-style-type: upper-alpha;
}
ol.otl4 {
  list-style-type: lower-alpha;
}
/* Search Content */
#searchContent {
  margin-top: 60px;
  padding: 60px 30px 20px 30px;
  transition: all 0.3s;
}
#searchBox {
  background-color: #fff;
  border: 1px solid black;
  margin-bottom: 10px;
  padding: 12px;
  text-align: justify;
}
#searchTitle {
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  border-bottom: 1px solid #0b91d7;
  padding-bottom: 10px;
  margin-bottom: 10px;
}
#searchBox .section > p {
  text-align: justify;
}
#searchBox h2 {
  text-align: justify;
  color: #000;
  font-weight: normal;
  font-size: 17px;
}
#searchBox li {
  display: block;
}
.highlight {
  background-color: lightgreen;
}
/* Hymns */
div.hymn {
  font-size: 17px;
  margin: auto;
  display: table;
}
p.hymnsep {
  line-height: 100%;
  margin-top: 0;
  margin-bottom: 0;
  min-height: 1em;
  padding-bottom: 0;
}
p.hymn1 {
  margin: 0;
  padding-left: 3.5em;
  text-indent: -2em;
  text-align: left;
  padding-bottom: 0;
}
p.hymn2 {
  margin: 0;
  padding-left: 4.5em;
  text-indent: -2em;
  text-align: left;
  padding-bottom: 0;
}
/* Text Align */
.right {
  text-align: right;
}
/* Author */
p.author {
  text-indent: 0;
  margin-top: 0.5em;
}
p.greeting {
  margin-left: 1.5em;
  text-indent: -1.5em;
}
p.image {
  text-align: center;
  text-indent: 0;
}
img {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  height: auto;
}
p.question {
  font-style: italic;
}
p.noindent {
  margin-left: 0.125em;
  margin-right: 0.125em;
  margin-top: 0.313em;
  margin-bottom: 0.313em;
  text-indent: 0;
}
p.source {
  font-size: 15px;
  margin-top: 1em;
}
/* End Reading */
#contentBody {
  padding: 0 20px 20px 20px;
}
#endReading {
  background-color: #d4edd9;
  color: #28a745;
  font-weight: bold;
  padding: 5px 0px;
}
/* Bookmark */
#bookmark {
  cursor: pointer;
}
#bookmarkTable > tbody > td {
  font-size: 16px !important;
}
